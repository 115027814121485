@charset "utf-8";
/*Uso de letra predeterminada para toda la pagina web*/
@font-face {
    font-family: "PrincipalFont_Bold";
    font-style: normal;
    font-weight: bold;
    src: local("?"),url("/css/fonts/seido/BwSeidoRoundDEMO-Bold.woff") format("woff"), url("/css/fonts/seido/BwSeidoRoundDEMO-Bold.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "PrincipalFont_Light";
    font-style: normal;
    font-weight: normal;
    src: local("?") ,url("/css/fonts/seido/BwSeidoRoundDEMO-Light.woff") format("woff"), url("/css/fonts/seido/BwSeidoRoundDEMO-Light.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "PrincipalFont_Regular";
    font-style: normal;
    font-weight: normal;
    src: local("?"), url("/css/fonts/seido/BwSeidoRoundDEMO-Regular.woff") format("woff"),url("/css/fonts/seido/BwSeidoRoundDEMO-Regular.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "PrincipalFont_Thin";
    font-style: normal;
    font-weight: normal;
    src: local("?"),url("/css/fonts/seido/BwSeidoRoundDEMO-Thin.woff") format("woff"), url("/css/fonts/seido/BwSeidoRoundDEMO-Thin.ttf") format("truetype");
    font-display: swap;
}
